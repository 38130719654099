* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

html, body {
  height: 100vh; /* Set the height of html and body to 100% */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.desktop-h1 {
  font-size: 2em;
}

.navbar {
  background: rgba(255, 255, 255, 0.15); 
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 10%;
}

.about {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 2%;
  position: absolute;
  top: 8%;
  left: 1%;
  background: rgba(255, 255, 255, 0.25); /* Transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.navbar-glass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.contact-form-shown {
  position: absolute;
  top: 8%;
  right: 4%;
}

.contact-form-hidden {
  display: none;
}


.education {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 2%;
  padding-bottom: 3%;
  position: absolute;
  top: 8%;
  right: 2%;
  background: rgba(255, 255, 255, 0.25); /* Transparent white */
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.footer {
  background-color: rgb(75, 74, 75);
  height: 10%;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.github-logo {
  height: 50px;
  width: 200px;
}

.linkedin-logo {
  height: 50px;
  width: 200px;
  
}

.logo-box {
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: center;
  position: absolute;
  top: 8%;
  left: 40%;
}

.skill-row {
  height: 25%;
  background-color: rgb(42, 6, 70);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gradient-bg {
  background-image: linear-gradient(90deg, rgb(34, 211, 238), rgb(37, 99, 235));
  height: 200%;
}

.full-stack {
  height: 105%;
}

.main {
  height: 120vh;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.skill-logo-stats {
  height: 100%;
  width: 13%;
}

.skill-logo-medium {
  height: 100%;
  width: 45%;
}

.skill-logo-large {
  height: 100%;
  width: 15%;
}

.education-logo {
  height: 11%;
  width: 11%;
}

.contact-logo {
  height: 10%;
  width: 10%;
}

.resume {
  top: 10%;
  right: 4%;
  position: absolute;
}

.web-dev {
  position: absolute;
  top: 8%;
  left: 13%;
  height: 70%;
}

.data-science {
  position: absolute;
  top: 8%;
  right: 8%;
  height: 70%;
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .mobile-text-h1 {
    font-size: 1rem;
  }
  .navbar {
    background: black;
  }
  .footer {
    justify-content: space-between;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;